
.tableOfContents {
    display: block;
    /* background-color: #; */
    padding: 10px 20px;
    margin: 10px 0;

    border-radius: 3px;
    border: 2px solid #EEE;
}

@media (prefers-color-scheme: dark) {
    .tableOfContents {
        border: 2px solid rgb(15, 15, 15);
    }
}

.tableOfContents h2 {
    margin-bottom: 5px;
}

.tableOfContents i {
    font-size: 0.8rem;
}

.codeElement {

    padding: 2px;
    border-radius: 2px;
    background-color: #EEE;

    font-family: 'Fira Code', 'Courier New', Courier, monospace;

}

.display {
    display: block;
    padding: 20px;
    margin: 10px 0;
}