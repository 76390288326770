:root {
    --font: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    --color: #222;
    --bg-color: #FAFAFA;
    --font-size: 1rem;
    --link-color: #706993;

    --accent-1: #EEE;
}

body {
    font-family: var(--font);
    max-width: 700px;
    margin: 20px auto;

    padding: 0 20px;

    color: var(--color);
    background-color: var(--bg-color);
}

a { text-decoration: none; color: var(--link-color) }
a:hover {text-decoration: underline;}

@media (prefers-color-scheme: dark) {

    :root {
        --color: #FAFAFA;
        --bg-color: #222;
        --link-color: lightblue;
        --accent-1: rgb(15, 15, 15)
    }

}

@media print {
    body {
        max-width: unset;
    }
}

footer {

    display: flex;
    justify-content: space-between;
    align-items: center;

    opacity: 0.5;
    user-select: none;

}

button.anchor {
    display: inline;
    background: none;
    padding: 0; margin: 0;

    border: none;
    font-family: var(--font);
    color: var(--link-color);
    font-size: var(--font-size);

    cursor: pointer;
}

button.anchor:hover { text-decoration: underline; }

.exportReady {

    padding: 20px;
    border-radius: 2px;
    margin: 20px 0;
    border: 2px solid var(--accent-1);

}

input.wide {
    padding: 10px;
    color: var(--color);
    font-family: var(--font);
    font-size: var(--font-size);
    background-color: var(--bg-color);

    border: 1px solid #706993;
    display: block;
    border-radius: 2px;
    width: 100%;
}

.widelink {

    display: inline-block;

    padding: 10px;
    color: black;
    background-color: lightcoral;
    border-radius: 2px;

    padding: 5px 10px;
    margin: 10px 0;

}

.widelink:hover { text-decoration: none; }